<template>
  <v-layout shrink class="badge-wrapper" :style="{ backgroundColor: color }">
    <div class="badge-icon" :style="{ color: fontColor }">{{ icon }}</div>
    <div class="badge-text" :style="{ color: fontColor }"><slot></slot></div>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    color: {
      default: "#e2f6ea",
    },
    fontColor: {
      default: "#00a34b",
    },
    icon: {
      default: "",
    },
  },
  components: {},
};
</script>

<style scoped>
.badge-wrapper {
  border-radius: 0.25rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.badge-text {
  font-size: 10px;
  font-weight: 600 !important;
}
.badge-icon {
  font-family: "b-icons";
  font-size: 11px;
  font-weight: 600;
  padding-right: 4px;
}
</style>